import React from "react";
import {graphql, Link} from "gatsby";
import AboutUs from "../components/homepage/home-about"
import ServiceLinkUnderline from "../components/serviceLinkUnderline";
import HomeLayout from "../layouts/homelayout";
import FeatureBanner from "../components/homepage/featurebanner"
import Features from "../components/homepage/features"
import styled from "styled-components";
import Seo from "../components/seo"

const Wrapper = styled.div`
  width:100%;
  padding: 3em 1em 5em 1em;
  background: rgb(16,15,38);
  background: linear-gradient(90deg, rgba(16,15,38,1) 0%, rgba(84,108,138,1) 100%);

  @media(min-width: 768px){
    padding: 3em 0 5em;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1150px;

  margin: 0 auto;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Header = styled.h2`
  width: 100%;
  text-align: center;
  color:#fff;
  font-weight: 700;
  padding: .5em 0;
  margin-top: 3rem;

  a{color:#fff;}
`

const Services = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  

@media(min-width:768px){
  flex-direction: row;
}
`

const Morelink = styled(Link)`
  text-align: center;
  color: #fff;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #e18a29;
  margin: 1em auto;
  font-size: 0.9em;
  padding: 10px;

  :hover{
    border: 2px solid transparent;
    background-color: #e18a29;
    color: #100f26;
    text-decoration: none;

    a{
      text-decoration: none;
    }
    p{
      color: #100f26;
    }
  }

  a{
    color: #000;

    :hover{text-decoration: none;}
  }
`

class IndexPage extends React.Component {


    render() {
        const { data } = this.props
        return (
            <HomeLayout>
                <Seo title="Element Physiotherapy Sports and Exercise Rehabilitation Centre" />
                <FeatureBanner image={data.banner.childImageSharp.gatsbyImageData} />
                <Wrapper>
                    <Container>
                        <Header><Link to="/">Online Physio Consults</Link></Header>
                        <Morelink to="/services/online-physio-consults/">Learn more about Online Physio Consults</Morelink>

                        <Header><Link to="/services/physiotherapy-services/">Physiotherapy Services</Link></Header>
                        <Services>
                            {this.props.data.physioServices.edges.map(service => {
                                // filter out drafts in production
                                return (
                                    <ServiceLinkUnderline
                                        key={service.node.id}
                                        service={service.node}
                                    />
                                );
                            })}
                        </Services>

                        <Header>
                            <Link to="/services/massage">Massage</Link>
                        </Header>
                        <Morelink to="/services/massage">Learn more about Massage</Morelink>

                        <Header>
                            <Link to="/services/supervised-exercise/">Supervised Exercise Services
                            </Link>
                        </Header>
                        <Services>
                            {this.props.data.supervisedServices.edges.map(service => {
                                // filter out drafts in production
                                return (
                                    <ServiceLinkUnderline
                                        key={service.node.id}
                                        service={service.node}
                                    />
                                );
                            })}
                        </Services>
                    </Container>
                </Wrapper>
                <AboutUs/>
                <Features image={data.features.childImageSharp.gatsbyImageData} alt="A grid of 4 images about us"/>
            </HomeLayout>
        )
    }
}

export default IndexPage

export const pageQuery = graphql`
    query {
         physioServices: allMarkdownRemark(
            sort: {fields: frontmatter___title, order: ASC}
            filter: {frontmatter: {tags: {eq: "physiotherapy"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                          gatsbyImageData(quality: 60, formats: [AUTO, WEBP], placeholder: BLURRED)
                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                timeToRead
                id
              }
            }
          }
          supervisedServices: allMarkdownRemark(
            sort: {fields: frontmatter___title, order: ASC}
            filter: {frontmatter: {tags: {eq: "supervised-exercise"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                                    gatsbyImageData(quality: 60, formats: [AUTO, WEBP], placeholder: BLURRED)

                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                timeToRead
                id
              }
            }
          }
          banner: file(relativePath: {eq: "FeaturedSlider/slide-Couple-Running.png"}) {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 90
              )
            }
          } 
          features: file(relativePath: {eq: "physio-features-grid.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                   placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.8
                  quality: 90
              )
            }
          }
    }
    
`;
