
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import {Link} from "gatsby";

const Header = styled.header`
    position: relative;
    height: calc(60vh - 200px);
    min-height: 500px;
        
    background-position: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

`

const Text = styled.div`
    position: absolute;
    top: 2em;
    left: 2em;
    height: auto;
    width: calc(100% - 4rem);
      max-width: 1000px;
      margin: auto;
      z-index: 50;
  text-align: left;
    
    h2{
        font-size: 2em;
        font-weight: 700;
        font-family: Roboto, sans-serif;
        color: #100f26;
    }
    
    @media(min-width: 768px){
        top: 3rem;
        left: 2rem;
        min-width: 20rem;
        width: 45%;
        
        h2{
            font-size: 3em;
            text-align: left;
        }
    }
`

const Button = styled(Link)`
  color: #fff;

   
  background-color: #e18a29;
    border-radius: 5px;
    border: 2px solid #e18a29;
    margin-left: 0 !important;
    margin-top: 1em;
    font-size: 0.9em;
    padding: 1rem;

    :hover{
      border: 2px solid transparent;
      background-color: transparent;
      color: #fff;
      text-decoration: none;
      
    }
`

const Background = styled(GatsbyImage)`
  display: block;
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
`
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

class FeatureBanner extends React.Component {
    render() {
        return(
            <Header className="header">
                <Text>
                    <h2>Empowering you to improve your health and wellbeing.</h2>
                    <Button to="/bookings">Book and Appointment</Button>
                </Text>
                <Background image={this.props.image} alt="Featured banner image" />
            </Header>
        );
    }
}

export default FeatureBanner

