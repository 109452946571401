/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import styled from "styled-components"

import Header from "../components/header/header"
import Footer from "../components/footer"
import "../styles/global-style.css"

const SiteWrapper = styled.div`
    position: relative;
`

class HomeLayout extends React.Component {
    render() {
        return (
            <SiteWrapper>
                <Header />
                <div>
                    <main>{this.props.children}</main>
                </div>
                <Footer />
            </SiteWrapper>


        );
    }
}

export default HomeLayout;

