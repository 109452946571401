import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Wrapper = styled.div`
    margin: 0;
    width: 100%;
    padding: 1em 0;
    text-align: center;
      @media(min-width: 768px){
            margin: 5em 0;
            padding: 4em 0;
      }
`

const Container = styled.div`
    width: 100%;
    max-width: 1330px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
      @media(min-width: 768px){
        flex-direction: row;
      }
`


class Features extends React.Component {
    render() {
        return(
            <Wrapper>
                    <Container id="features">
                        <GatsbyImage className="featureimage" image={this.props.image} />

                        <div className="features">
                            <ul  >
                                <li className="feature"><p>Leaders in diagnosis and management <span className="tick">&#10003;</span>
                                </p></li>
                                <li className="feature"><p>Keeping up to date with research and clinical practice <span className="tick">&#10003;</span>
                                </p></li>
                                <li className="feature"><p>Holistic approach to health <span className="tick">&#10003;</span>
                                </p></li>
                                <li className="feature"><p>Longer consultation times <span className="tick">&#10003;</span>
                                </p></li>
                                <li className="feature"><p>Communication with your GP or Specialist <span className="tick">&#10003;</span>
                                </p></li>
                            </ul>
                        </div>
                    </Container>
                </Wrapper>
            );
    }
}

export default Features

