import React from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import Chevron from "../images/right-arrow.png";

const Service = styled.div`
  display:flex;
  position: relative;
  width: 100%;

  font-size: 1.2em;
  font-weight: 300;
  text-align: left;

  color: #fff;
  margin: 0.5em 1em;
  padding: .4em 0;
  text-decoration: none;
  letter-spacing: 1px;
  border-bottom: 1px solid #e18a29;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;

  @media (min-width:576px) {
    width: calc(50% - 4em);
  }

  @media (min-width:786px) {
    width: calc(33% - 2em);
  }

  a {
    color: #fff;
  }

  ::after{
    background: url(${Chevron}) no-repeat right 5px center;
    content:'';
    display:block;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 4px;
    line-height: inherit;
    top: 30%;
    transition: 200ms;
  }

  a{
    color:#fff;
    display: block;
    margin: auto 0;
    width: calc(100% - 25px);
    line-height: 1.4em;
    
    :hover{ text-decoration: none;}
  }

  :hover{
    ::after{
      right: 16px;
      transition: 200ms;
    }
    a{
      text-decoration: none;
      color:#fff;
    }
  }
`

class ServiceLinkUnderline extends React.Component {

    render() {
        const service = this.props.service;

        return(
            <Service>
                <Link to={service.fields.slug}>
                        {service.frontmatter.title}
                </Link>
            </Service>
        );
    }
}

export default ServiceLinkUnderline;
