import {graphql, Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "../../images/erina.png"

import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    max-width: 1330px;
    position: relative;
    margin: 2em auto;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
        @media(min-width: 768px){
          flex-direction: row;
          justify-content: space-between;
        }
`

const AboutText = styled.div`
 width: calc(100% - 2em);
 margin: 1em;
flex: auto; 
  @media(min-width: 768px){
      width: calc(50% - 4em);
      margin: 1em 3em 1em 1em;
       flex: 1;

  }
`

const MainImage = styled.div`
    width: calc(100% - 2em);
    height: 300px;
    background-position: center;
    background-size: cover;
    background-image:url(${Img});
    margin: 1em 3em 1em 1em;
    flex: auto;
    
      @media(min-width: 768px){
        width: calc( 50% - 2em);
        height: auto;
         flex: 1;

      }

        
`

const Video = styled.video`
    width: calc(50% - 4em);
    margin: 1em 1em 1em 3em;
    
    display: flex;
    justify-contents: centre;
    align-items: center;
    object-fit: cover;
    
    box-shadow: 0 0 4px 4px #ECECEC;
`


const AboutUs = () => (
    <Container>
        <AboutText>
            <h2>About Element Physio</h2>
            <p>If you’re looking for a physio who will take the time to understand your situation before recommending a comprehensive treatment plan that doesn’t cost you a fortune, then welcome to Element Physio.</p>

            <p>Element Physio treats all types of injuries, from everyday aches, to traumatic injuries, work and car accident related injuries, pre- and post-operative and sports rehabilitation for people of all ages.</p>

            <p>Best of all, you’ll experience excellence in patient care as our post-graduate qualified physiotherapists deliver best practice services.</p>

            <p>Element Physio are registered NDIS providers, provide home visits across the Central Coast and offer after hours and emergency physiotherapy services.</p>

            <p><Link to="about-us">Click here</Link> to learn more about us.</p>
        </AboutText>
        <MainImage alt="External photo of the Element Building" />
    </Container>
)

export default AboutUs



